<template>
  <v-container>
    <v-btn color="primary" class="mb-2" @click="createPost()">Новый пост</v-btn>
    <v-row>
      <v-col>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th>ID</th>
                <th>Название</th>
                <th>Публикаций</th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(post, i) in posts" :key="i">
                <td>{{ post.id }}</td>
                <td>{{ post.name }}</td>
                <td>{{ post.publications.length }}</td>
                <td>
                  <v-btn icon @click="showEditDialog(post)"><v-icon>{{ mdiPencil }}</v-icon></v-btn>
                  <v-btn icon @click="copyPost(post)"><v-icon>{{ mdiContentCopy }}</v-icon></v-btn>
                </td>
                <td><v-btn text @click="createPublication(post)">Купить рекламу</v-btn></td>
                <td><v-btn :class="{primary: favPosts.some(x=>x===post.id)}" icon @click="toggleFav(post)"><v-icon>{{ mdiStar }}</v-icon></v-btn></td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
      <v-dialog v-model="editDialog" width="800px">
        <perfect-scrollbar :options="{suppressScrollX: true}">
          <ad-post v-model="editItem" @close="editDialog=false" @createPublication="createPublication"></ad-post>
        </perfect-scrollbar>
      </v-dialog>
      <v-dialog v-model="publicationDialog" width="90rem">
      <!-- <perfect-scrollbar :options="{suppressScrollX: true}"> -->
        <edit-publication :publication="publicationItem" @close="publicationDialog=false"></edit-publication>
      <!-- </perfect-scrollbar> -->
    </v-dialog>
  </v-container>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import {mdiPencil, mdiStar, mdiContentCopy} from '@mdi/js'
import store from '@/store'
import AdPost from './AdPost.vue'
import EditPublication from '../publications/EditPublication.vue'

export default{
  name: 'AdPosts',
  components:{AdPost, EditPublication},
  setup(){
    const editItem = ref({})
    const editDialog = ref(false)
    const publicationItem = ref({})
    const publicationDialog = ref(false)
    const allPosts = computed(()=>store.state.adPosts.filter(x=>x.is_active))
    const posts = computed(()=>allPosts.value.filter(x=>x.is_active))
    const favPosts = computed(()=>store.state.favPosts)
    const adPostCategories = computed(()=>store.state.adPostCategories)

    const createPost = () =>{
      editItem.value = {post: ' yo'}
      editDialog.value = true
    }

    const createPublication = post =>{
      console.log('create publication: ', post)
      publicationItem.value = {id: 0, bot_id: post.bot_id, ad_post_id: post.id, channel_id: post.channel_id, price: 0, create_join_request: 1}
      publicationDialog.value = true
      editDialog.value = false
    }

    const showEditDialog = post =>{
      editItem.value = post
      editDialog.value = true
      // editItem=post; editDialog=true
    }

    const toggleFav = item =>{
      store.dispatch('add', {mutation: 'toggleFavPost', payload: item.id})
    }

    const copyPost = post =>{
      editItem.value = {...post, id: 0, name: post.name + ' (копия)'}
      editDialog.value = true
    }

    return {
      posts, favPosts,
      editItem, editDialog,
      publicationItem, publicationDialog,
      adPostCategories,
      createPost, showEditDialog, toggleFav, createPublication, copyPost,
      mdiPencil, mdiStar, mdiContentCopy,
    }
  }
}
</script>

<style scoped>
.ps{
  height: 80%;
}
</style>
